import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from './pages/header'
import Footer from './pages/footer'
import About from './pages/about'
import Pricing from './pages/pricing'
import ContactUs from './pages/ContactUs'
import Application from './pages/Application'
import Home from './pages/Home';
import './i18n';

const Routing = () => {
    return(
        <Router>
            <Header/>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/app" component={Application} />
                <Route path="" component={ContactUs} />
            </Switch>
            <Footer/>
        </Router>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <Routing />
    </React.StrictMode>,
    document.getElementById('root')
);
