import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import config from '../config.json'
export default function Header() {
    const {t,i18n} = useTranslation();
    const [OpenMenu, setOpenMenu] = useState(false);

    return (
        <>
            <menu>
                <div className={'menu-mobile'} onClick={()=>{setOpenMenu(!OpenMenu)}}><img src={'/assets/menu.png'} /> </div>
                <Link to="/"> <img src={'/assets/logo.svg'}  className={'headerLogo'}/></Link>
                <ul className={OpenMenu ? "menuShow" : ""}>
                    <li><Link to="/app">{t('menu.howDoesItWork')}</Link></li>
                    <li><Link to="/pricing">{t('menu.pricing')}</Link></li>
                    <li className={'langMenu'}> <Link to="#" >{i18n.language}</Link>
                    <ul>
                        {config.lang.map((ln)=>
                            <li  className={ i18n.language === ln.key ? 'activeLang' : ''}>
                                <span onClick={()=>{i18n.changeLanguage(ln.key)}}>{ln.name}</span>
                            </li>
                        )}


                    </ul>
                    </li>
                    <li><Link to="/" className={'SignBtn'}>{t('menu.signUp')}</Link></li>
                </ul>
            </menu>
        </>
    )
}
