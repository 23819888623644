import React from 'react'
import {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Pricing() {
    const {t} = useTranslation();
    const [Switch, setSwitch] = useState(false)
    return (
        <>
            <div className={'pricing'}>
                <div className={'title-pricing'}>
                    <div className={'title'}>{t("pricing.title")}</div>
                    <div className={'pricingPeriod'}>
                        {t("pricing.payYearly")}
                        <span className={'offer'}>{t("pricing.offer")}</span>
                        <div className={Switch ? 'switchDiv ' : 'switchDiv switchDivActive'} onClick={() => {
                            setSwitch(!Switch)
                        }}>
                            <div className={'switchAngle'}></div>
                        </div>

                        {t("pricing.payMonthly")}
                    </div>
                </div>
                <div className={'pricing-fa'}>
                    <div className={'price-item'}>
                        <div className={'price-item-header'}>
                            <div className={'price-title'}>
                                {t("pricing.plan1.title")}
                                <img src={'/assets/icon-5.svg'} className={'planShape'}/>
                            </div>
                            <div className={'price-header'}>
                                <div className={'price-number'}>{t('pricing.plan1.price')}</div>
                                <span>{t("pricing.plan1.title")}</span>
                            </div>
                        </div>
                        <div className={'price-decs'}>
                            <div>{t("pricing.plan1.op1")}</div>
                            <div>{t("pricing.plan1.op2")}</div>
                            <div>{t("pricing.plan1.op3")}</div>
                            <div>{t("pricing.plan1.op4")}</div>
                        </div>
                        <a href={'#'} className={'planLink'}>{t("pricing.contactUs")}</a>
                    </div>
                    <div className={'price-item'}>
                        <div className={'price-item-header'}>
                            <div className={'price-title'}>
                                Company
                                <img src={'/assets/shape8.png'} className={'planShape'}/>

                            </div>
                            <div className={'price-header'}>
                                <div className={'price-number'}>{t('pricing.plan2.price')}</div>
                                <span>{t("pricing.plan2.title")}</span>
                            </div>
                        </div>
                        <div className={'price-decs'}>
                            <div>{t("pricing.plan2.op1")}</div>
                            <div>{t("pricing.plan2.op2")}</div>
                            <div>{t("pricing.plan2.op3")}</div>
                            <div>{t("pricing.plan2.op4")}</div>
                        </div>
                        <a href={'#'} className={'planLink'}>{t("pricing.contactUs")}</a>
                    </div>
                    <div className={'price-item'}>
                        <div className={'price-item-header'}>
                            <div className={'price-title'}>
                                Company
                                <img src={'/assets/icon-4.svg'} className={'planShape'}/>

                            </div>
                            <div className={'price-header'}>
                                <div className={'price-number'}>{t('pricing.plan3.price')}</div>
                                <span>{t("pricing.plan3.title")}</span>
                            </div>
                        </div>
                        <div className={'price-decs'}>
                            <div>{t("pricing.plan3.op1")}</div>
                            <div>{t("pricing.plan3.op2")}</div>
                            <div>{t("pricing.plan3.op3")}</div>
                            <div>{t("pricing.plan3.op4")}</div>
                        </div>
                        <a href={'#'} className={'planLink planLinkActive'}>{t("pricing.currantPlan")}</a>
                    </div>
                </div>
                <section className="section sectionShape">
                    <div className="sectionData sectionJet">

                        <div className="sectionIcon sectionIcon2 "><img src="/assets/jet.png"/></div>
                        <div className="sectionDesc">
                            <div className="sectionDescTitle">{t('pricing.desc')}
                                <div>
                                    <a href={'/contact-us'} className={'sectionBtn'}>{t("pricing.contactUs")}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
