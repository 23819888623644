import {useState} from 'react';
import {useTranslation} from 'react-i18next';

function Home() {
    const {t} = useTranslation();
    const [Email, setEmail] = useState('')
    const [Subscribed, setSubscribed] = useState(false)
    const [EmailError, setEmailError] = useState(false)

    function subscribe() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(Email) ) {
            setEmailError(false)
            fetch("https://api.mailerlite.com/api/v2/subscribers/", {
                headers: {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                    "X-MailerLite-ApiKey": "9b0681e22e372de9d732be210dd3e667",
                },
                method: 'POST',
                body: {
                    "email": Email
                }
            })
                .then(res => {
                    console.log(res)
                    return (res.ok) ? res.json() : new Error("Mistake!")
                })
                .then(shows => {
                    setSubscribed(true)
                })
                .catch(error => {
                    console.log(error)
                })
        }else{
setEmailError(true)
        }

    }

    return (
        <div className="homePage">
            <section className={'section sliderSection'}>
                <div className={'SliderDesc'}>
                    <h2>
                        {t('home.Slider.heading')}
                    </h2>
                    <div className={'SliderDescription'}>
                        {t('home.Slider.description')}
                    </div>
                    <div>
                        <a href={''} className={'SignBtn'}>{t('menu.signUp')}</a>
                        <a href={''} className={''}>{t('menu.howDoesItWork')}</a>
                    </div>
                </div>
                <div className={'SliderImage'}>
                    <img src={'/assets/QikooAppSc.png'}/>
                </div>
            </section>
            <section className={'section blueSection'}>
                <div className={'imageFa'}>
                    <img src={'/assets/light.png'}/>

                </div>
                <div className={'vision'}>
                    {t('home.vision')}
                </div>
            </section>
            <section className={'section'}>
                <div className={'title'}>{t("home.how")} <span> {t('general.Qikoo')} </span> {t('home.works')}</div>
                <div className={'sectionData sectionDataIconText'}>

                    <div className={'iconItem'}>
                        <div className={'iconFa'}>
                            <img src={'/assets/ReadyToGo.png'}/>
                        </div>
                        <div className={'iconTitle'}>{t("home.icons.SetupCompany")}</div>
                        <div className={'iconDesc'}>{t("home.icons.SetupCompanyDesc")}</div>
                    </div>
                    <div className={'iconItem'}>
                        <div className={'iconFa'}>
                            <img src={'/assets/AddYourStaff.png'}/>
                        </div>
                        <div className={'iconTitle'}>{t("home.icons.AddYourStaff")}</div>
                        <div className={'iconDesc'}>{t("home.icons.AddYourStaffDesc")}</div>
                    </div>
                    <div className={'iconItem'}>
                        <div className={'iconFa'}>
                            <img src={'/assets/SetupCompany.png'}/>
                        </div>
                        <div className={'iconTitle'}>{t("home.icons.ReadyToGo")}</div>
                        <div className={'iconDesc'}>{t("home.icons.ReadyToGoDesc")}</div>
                    </div>
                </div>
            </section>
            <section className={'section sectionShape'}>
                <div className={'title'}>{t("home.what")} <span> {t('general.Qikoo')} </span> {t('home.suggests')}</div>
                <div className={'sectionData'}>
                    <div className={'shape shape1'}/>
                    <div className={'shape shape2'}/>
                    <div className={'shape shape3'}/>
                    <div className={'shape shape5'}/>
                    <div className={'sectionDesc'}>
                        <div className={'sectionDescTitle'}>{t("home.ManageDistractions")}</div>
                        <div className={'sectionDescText'}>{t("home.ManageDistractionsDesc")}</div>
                    </div>
                    <div className={'sectionIcon'}>
                        <img src={'/assets/characterHome.png'}/>
                    </div>
                </div>
            </section>
            <section className={'section sectionShape'}>
                <div className={'sectionData'}>
                    <div className={'shape shape6'}/>
                    <div className={'shape shape7'}/>
                    <div className={'shape shape8'}/>
                    <div className={'shape shape9'}/>
                    <div className={'sectionIcon sectionIcon2'}>
                        <img src={'/assets/characterGreen.png'}/>
                    </div>
                    <div className={'sectionDesc'}>
                        <div className={'sectionDescTitle'}>{t("home.ManageDistractions")}</div>
                        <div className={'sectionDescText'}>{t("home.ManageDistractionsDesc")}</div>
                    </div>

                </div>
            </section>
            <section className={'section sectionShape'}>
                <div className={'sectionData'}>
                    <div className={'shape shape10'}/>
                    <div className={'shape shape11'}/>
                    <div className={'shape shape12'}/>
                    <div className={'shape shape13'}/>

                    <div className={'sectionDesc'}>
                        <div className={'sectionDescTitle'}>{t("home.ManageDistractions")}</div>
                        <div className={'sectionDescText'}>{t("home.ManageDistractionsDesc")}</div>
                    </div>
                    <div className={'sectionIcon sectionIcon2'}>
                        <img src={'/assets/characterBlue.png'}/>
                    </div>
                </div>
            </section>
            <section className={'section sectionSub'}>
                <div className={'sectionSubDesc'}>
                    {t('home.subscribe')}
                </div>
                <div className={'sectionSubInput'}>
                    {EmailError && <div>{t("home.SubscribeError")}</div>}
                    {Subscribed ? <div>{t("home.SubscribedMessage")}</div> :
                        <>
                        <input placeholder={'name@work-email.com'} type={'text'} onChange={(event => setEmail(event.target.value))} value={Email}/>
                        <span onClick={() => {
                        subscribe()
                    }}>{t('home.submit')}</span>
                        </>
                    }
                </div>
            </section>
        </div>
    );
}

export default Home;
