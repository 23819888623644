import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";

export default function Application() {
    const {t} = useTranslation();

    return (
        <>
            <div className={'Application'}>
                <div className={'scView'}>
                    <div className={'scInfo'}>
                        <div className={'title'}>{t('application.title')}</div>
                        <div className={'desc'}>{t('application.desc')}</div>
                    </div>
                    <div className={'scs'}>
                        <img src={'/assets/sc1.png'}/>
                        <img src={'/assets/sc2.png'}/>
                    </div>

                </div>
                <div className={'scView'}>
                    <div className={'scs'}>
                        <img src={'/assets/sc3.png'}/>
                        <img src={'/assets/sc4.png'}/>
                    </div>
                    <div className={'scInfo'}>
                        <div className={'title'}>{t('application.title1')}</div>
                        <div className={'desc'}>
                            {t('application.desc1')}
                        </div>
                    </div>


                </div>

                <div className={'scView'}>
                    <div className={'scInfo'}>
                        <div className={'title'}>{t('application.title2')}</div>
                        <div className={'desc'}>{t('application.desc2')}</div>
                    </div>
                    <div className={'scs'}>
                        <img src={'/assets/sc5.png'}/>
                        <img src={'/assets/sc6.png'}/>
                    </div>

                </div>
            </div>
        </>
    )
}
