import React from 'react'
import {Link} from 'react-router-dom'

export default function header() {
    return (
        <>
            <div>
                ABOUT
            </div>
        </>
    )
}
