import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "en",
        fallbackLng: "en",
        debug: true,
        ns: "english",
        defaultNS: "english", // we do not use keys in form messages.welcome
        react: {
            useSuspense: false,
        },
        saveMissing: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        backend: {
            loadPath: "/locales/{{lng}}.json",
        },
    });

export default i18n;
