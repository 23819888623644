import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";

export default function ContactUs() {
    const {t} = useTranslation();

    return (
        <>
            <div className={'ContactUs'}>

                <section className="section sectionShape">
                    <div className="sectionData sectionJet sectionContact">

                        <div className="sectionIcon sectionIcon2 "><img src="/assets/sayHello.png"/></div>
                        <div className="sectionDesc">
                            <div className="sectionDescTitle">{t('contactUs.title')}</div>

                        </div>
                    </div>
                </section>
                <div className={'ContactUs-fa'}>

                    <div className={'address'}>
                        <div className={'ourTeam'}>
                            <div className={'title'}>

                                {t('contactUs.title1')}
                            </div>
                            <div className={'ourTeamDesc'}>{t('contactUs.title1Desc')}</div>
                        </div>
                        <div className={'address-info'}>
                            <div className={'address-infoCol'}>
                                <div className={'title'}>
                                    {t('contactUs.title2')}
                                </div>
                                <div className={'address-infoColDecs'}>
                                    {t('contactUs.title2Desc')}
                                </div>
                            </div>
                            <div className={'address-infoCol'}>
                                <div className={'title'}>

                                    {t('contactUs.title3')}
                                </div>
                                <div className={'address-infoColDecs'}>
                                    {t('contactUs.title3Desc')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'form-contact'}>
                        <input type={'text'} placeholder={'Email'}/>
                        <input type={'text'} placeholder={'Name'}/>
                        <textarea placeholder={t('contactUs.message')}></textarea>
                    </div>
                </div>
            </div>
        </>
    )
}
