import React from 'react'
import {Link} from 'react-router-dom'

export default function header() {
    return (
        <>
            <footer>
                <img className={'footerLogo'} src={'/assets/logofooter.svg'} />
                <div className={'footerInfo'}>
                    <div className={'footerLinks'}>
                        <a href={'#'}>©Qikoo ÖU</a>
                        <a href={'#'}>Terms of Use</a>
                        <a href={'#'}>Privacy Policy</a>
                    </div>
                    <div className={'footerSocial'}>
                        <a href={'#'}>Blog</a>
                        <a href={'/contact-us'}>Contact</a>
                        <a href={'#'}><img src={'/assets/facebook.png'}/> </a>
                        <a href={'#'}><img src={'/assets/instagram.png'}/></a>
                    </div>
                </div>
            </footer>
        </>
    )
}
